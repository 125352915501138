import React from "react"
import ListGroup from "react-bootstrap/ListGroup"
import email from "../assets/images/contact/email.svg"
import phone from "../assets/images/contact/phone.svg"
import whatsapp from "../assets/images/contact/whatsapp.svg"
import linkedin from "../assets/images/contact/linkedin.svg"
import language from "../assets/images/contact/language.svg"
import ConditionalWrapper from "./conditional-wrapper"

const TeamContactCard = ({ person, variant = '', showGaps = false, smallEmail = false }) => {
  return (<ListGroup variant={variant}>
    <ListGroup.Item action href={`mailto:${person.email}`}>
      <img src={email} alt='Email' className='icon mr-3'/>
      <ConditionalWrapper
        condition={smallEmail}
        wrapper={children =>
          <small>{children}</small>
        }
      >{person.email}</ConditionalWrapper>
    </ListGroup.Item>
    <ListGroup.Item action href={`tel:${person.phoneNumber}`}>
      <img src={phone} alt='Call' className='icon mr-3'/>
      {person.phoneNumber}
    </ListGroup.Item>
    <ListGroup.Item action href={`https://wa.me/${person.phoneNumber.replace(/[^0-9]/g, '')}`} target='_blank' rel='noreferrer'>
      <img src={whatsapp} alt='WhatsApp' className='icon mr-3'/>
      WhatsApp
    </ListGroup.Item>
    {person.linkedIn &&
    <ListGroup.Item action href={person.linkedIn} target='_blank' rel='noreferrer'>
      <img src={linkedin} alt='LinkedIn' className='icon mr-3' />
      LinkedIn
    </ListGroup.Item>
    }
    {!person.linkedIn && showGaps &&
    <ListGroup.Item>
      &nbsp;
    </ListGroup.Item>
    }
    {person.languages &&
      <ListGroup.Item>
        <img src={language} alt='Languages' className='icon mr-3' />
        {person.languages.join(", ")}
      </ListGroup.Item>
    }
  </ListGroup>);
};

export default TeamContactCard
