import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, Link } from "gatsby"
import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card"
import Row from "react-bootstrap/Row"
import { GatsbyImage } from "gatsby-plugin-image"
import TeamContactCard from "../components/team-contact-card"

const Contact = ({ data }) => {
    return (
      <Layout>

          <SEO title='Contact'/>
          <article>
            <h1>Contact</h1>
            <p className='lead'>
              For general enquiries, email <a href="mailto:info@bhkcrisiscommunications.com?subject=Enquiry">info@bhkcrisiscommunications.com</a>.
            </p>

            <Row xs={1} md={2} className='justify-content-center'>
              {data.people.nodes.map(person =>
                <Col className='mb-4' key={person.id}>
                  <Card className='component-contact-card'>
                    <Card.Body className='d-flex flex-column'>
                      <Card.Title className='mb-0'>
                        <Row>
                          <Col>
                            <Link className='text-body' to={`/team/${person.slug}`}>
                              {person.name}
                            </Link>
                          </Col>
                          <Col xs={5}>
                            <GatsbyImage
                              image={person.photo.gatsbyImageData}
                              alt={person.name}
                              className='rounded' />
                          </Col>
                        </Row>

                      </Card.Title>

                    </Card.Body>
                    <TeamContactCard person={person} variant='flush' showGaps={true}/>
                  </Card>
                </Col>
              )}
            </Row>


          </article>

      </Layout>
    );
}

export default Contact;

export const query = graphql`{
  people: allContentfulPerson(sort: {fields: index}, filter: {active: {eq: true}}) {
    nodes {
      id
      email
      slug
      name
      description
      phoneNumber
      linkedIn
      languages
      bio {
        raw
      }
      photo {
        gatsbyImageData(layout: CONSTRAINED, height: 286, width: 220)
      }
    }
  }
}
`
